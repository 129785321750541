// 引入
import req from '../utils/request.js'


/**
 * @param {*} query
 * @returns 全局menu
 */

export function queryMenuList(query) {
  return req.get(
    `/I18n/change/menu?lang=${query}`,
  )
}


/**
 * 思安
 * @param {*} query 
 * @returns 关于我们
 * @returns  公司简介
 */

//获取公司简介列表
export function queryCompanyList() {
  return req.get(
    `/company/company_info/list`
   
  )
}

//获取公司历程列表
export function queryHonorList(query) {
  return req.get(
    `/Honor/honor/desclist?pageSize=${query.pageSize}&pageNum=${query.pageNum}`,
  )
}


// 获取公司理念列表
export function getCompanyConceptList(query) {
  return req.get(
    `/companyConcept/companyConcept/list`,
  )
}

// 获取公司目标列表
export function queryTargetList(query) {
  return req.get(
    `/target/target/list`,
  )
}

// 公司使命
export function queryMissionList() {
  return req.get(
    `/mission/mission/list`
  )
}

/**
 * 
 * @param {*} query 
 * @returns 临床中心
 */

export function queryClinicalLayoutList() {
  return req.get(
    `/clinicalLayout/clinicalLayout/list`,
  
  )
}

/**
 * 
 * @param {*} query 
 * @returns 公司新闻
 */

export function queryNewCompanyList(query) {
  return req.get(
    `/newCompany/newCompany/list?pageSize=${query.pageSize}&pageNum=${query.pageNum}`,
  )
}

/**
 * 
 * @param {*} query 
 * @returns 行业资讯
 */
export function queryNewMediaList(query) {
  return req.get(
    `/newMedia/newMedia/list?pageSize=${query.pageSize}&pageNum=${query.pageNum}`
  )
}

/**
 * 
 * @param {*} query 
 * @returns 招贤纳士
 */
export function queryRecruitTalentList() {
  return req.get(
    `/peopleAdd/peopleAdd/list`
  )
}
/**
 * 
 * @param {*} query 
 * @returns 查询招聘岗位
 */
export function queryRecruitPositionList(query) {
  return req.get(
    `/recruit/recruit/list?sign=${query.sign}`
  )
}
/**
 * 
 * @param {*} query 
 * @returns 发展培训
 */
export function queryDevelopTraining() {
  return req.get(
    `/development/development/list`
  )
}

/**
 * 
 * @param {*} query 
 * @returns 联系我们
 */
export function contactUsInfo() {
  return req.get(
    `/contactUs/contactUs/list`
  )
}
/**
 * 
 * @param {*} query 
 * @returns 产品管线
 */
export function productpipeLine() {
  return req.get(
    `/ProductionPipelines/ProductionPipelines/list`
  )
}
/**
 * 
 * @param {*} query 
 * @returns 获取公司新闻详情
 */

export function queryNewCompanyDetial(id) {
  return req.get(
    `/newCompany/newCompany/${id}`,
  )
}
/**
 * 
 * @param {*} query 
 * @returns 获取行业资讯详情
 */

export function queryzixunDetial(id) {
  return req.get(
    `/newMedia/newMedia/${id}`,
  )
}
/**
 * 
 * @param {*} query 
 * @returns 获取国内法律法规
 */
export function getLawRule(query) {
  return req.get(
    `/laws/laws/list?pageSize=${query.pageSize}&pageNum=${query.pageNum}`
  )
}
// 获取交易并购列表
export function getTradeAnddEAL(query) {
  return req.get(
    `/deal/deal/list?pageSize=${query.pageSize}&pageNum=${query.pageNum}`
  )
}
// 获取交易并购详情
export function queryTradeDealDetial(id) {
  return req.get(
    `/deal/deal/${id}`,
  )
}
// 国际法律法规
export function getLawForignRule(query) {
  return req.get(
    `/international/international/list?pageSize=${query.pageSize}&pageNum=${query.pageNum}`
  )
}
// 国际法律法规详情 
export function getRegulageDetial(id) {
  return req.get(
    `/international/international/${id}`,
  )
}
// 新版公司历程


export function queryHonorListNew() {
  return req.get(
    `/honorNew/honorNew/list`,
  )
}
// 联系我们留言
export function leaveMessage(query) {
  return req.post(
    `/messageBoard/messageBoard/web`,query
  )
}
// 获取联系我们验证码
export function getCode() {
  return req.get(
    `/captchaImage`,
  )
}