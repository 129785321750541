<template>
  <div class="sub-layout">
    <div class="bannerBox" style="position: relative; margin-bottom: 24px">
      <!-- <img :src="bannerUrl" style="display: block; width: 100%"  /> -->
      <img style="display: block; width: 100%" v-if="$route.path == '/news/businessDeal'" src="@/assets/imgs/8.jpg" />
      <img style="display: block; width: 100%" v-if="$route.path == '/news/domesticRegulation'" src="@/assets/imgs/9.jpg" />
      <img style="display: block; width: 100%" v-if="$route.path == '/news/index' || $route.path == '/news/information' || $route.path == '/news/regulations'" src="@/assets/imgs/1.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/pipeline/index' || $route.path == '/clinical' || $route.path == '/pipeline'" src="@/assets/imgs/2.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/AboutUs/target'" src="@/assets/imgs/3.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/AboutUs/mission'" src="@/assets/imgs/4.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/' || $route.path == '/index' || $route.path == '/AboutUs/history'" src="@/assets/imgs/5.jpg" />
      <img style="display: block; width: 100%" v-if="$route.path == '/talent/index' || $route.path == '/contact/us'" src="@/assets/imgs/6.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/talent/training'" src="@/assets/imgs/7.png" />
      <img style="display: block; width: 100%" v-if="$route.path == '/AboutUs/index' || $route.path == '/AboutUs/idea'" src="@/assets/imgs/55.png" />
    </div>
    <div class="mainTitle">
      <!-- 导航名 -->
      <div class="ptitle" v-if="subactive != 'TalentIndex'">
        <div class="brand" :class="lang == 'en' ? '' : 'brandzh'" v-for="(item, index) in sublist" :key="index">
          <template v-if="subactive == item.meta.title">{{ globalI18nKey[item.meta.title] }}</template>
        </div>
      </div>
      <div class="rightMenu" v-if="subactive != 'TalentIndex' && subactive != 'TalentTraining' && subactive != 'concatUs' && subactive != 'PipelineIndex' && $route.path != '/news/detial'">
        <template v-for="(item, index) in sublist">
          <div v-if="!item.meta.hidden && lang == 'zh'" :key="index" class="subItem subItemZn" @click="itemClick(item)" :class="{ subactive: isSubActive(item) }">
            <div class="text">{{ globalI18nKey[item.meta.title] }}</div>
          </div>
          <div v-if="!item.meta.hidden && lang == 'en'" :key="index" class="subItem subItemEn" @click="itemClick(item)" :class="{ subactive: isSubActive(item) }">
            <div class="text">{{ globalI18nKey[item.meta.title] }}</div>
          </div>
        </template>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Bus from '@/utils/bus'
import { routes } from '@/router'
export default {
  name: 'SubLayout',
  components: {},
  data() {
    return {
      bannerUrl: require('@/assets/imgs/5.jpg'),
      sublist: [],
      currentMessage: {}
    }
  },
  computed: {
    path() {
      return this.$route.matched[1].path.slice(1)
    },
    lang() {
      return this.$store.state.lang
    },
    routName() {
      return this.$route.matched[1].meta.title
    },
    globalI18nKey() {
      return this.$store.state.globalI18nKey
    },
    subactive: {
      get() {
        return this.$store.state.subactive
      },
      set(val) {
        this.$store.commit('SET_SUBACTIVE', val)
      }
    }
  },
  watch: {
    path: {
      immediate: true,
      handler(val) {
        let parent
        routes[0].children.forEach((element) => {
          if (element.path == val) parent = element
        })
        this.sublist = parent.children
      }
    },

    lang: {
      handler(val) {
        if (val) {
          this.$store.dispatch('queryMenuList1', val)
        }
      }
    }
  },
  created() {},
  mounted() {},
  updated() {
    this.subactive = this.$route.name
  },
  methods: {
    isSubActive(subItem) {
      // const path = item.redirect ? item.redirect : '/' + item.path
      return subItem.name == this.subactive
    },
    // 页面内部导航
    itemClick(item) {
      this.subactive = item.name
      const path = '/' + this.path + '/' + item.path
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style scoped lang="less">
.bannerBox {
  img {
    width: 100%;
    height: 330px;
  }
}
.sub-layout {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 36px;
}

.subItem {
  position: relative;
  height: 42px;
  background: #ffffff;
  border-radius: 22px 0 22px 0;
  border: 1px solid #d4d4d4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.8s;
  overflow: hidden;
  .text {
    height: 21px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    text-align: center;
  }
}
.subItemEn {
  padding: 4px 20px;
  margin-left: 14px;
  .text {
    font-size: 16px;
  }
}
.subItemZn {
  letter-spacing: 3px;
  padding: 4px 20px;
  margin-left: 22px;
  .text {
    font-size: 20px;
  }
}
.subItem:after,
.subItem:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: -2;
  border-radius: 22px 0 22px 0;
  transition: all 0.5s;
  background: linear-gradient(to right, #0a72c2, #0a72c2) no-repeat;
}
.subItem:hover {
  z-index: 1;
  background: transparent;
  .text {
    color: #ffffff;
  }
}
.subItem:hover:after,
.subItem:hover:before {
  width: 100%;
  border-radius: 22px 0 22px 0;
}
.mainTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 300px 20px;
  .rightMenu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
.subItemZn {
  &.subactive {
    height: 42px;
    background: #0a72c2;
    border-radius: 22px 0 22px 0;

    .text {
      height: 21px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
    }
  }
}
.subItemEn {
  &.subactive {
    height: 42px;
    background: #0a72c2;
    border-radius: 22px 0 22px 0;

    .text {
      height: 21px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
    }
  }
}

.ptitle {
  border-bottom: 5px solid #0a72c2;
  padding-bottom: 10px;
  .brand {
    display: flex;
    flex: 1;
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 24px;
    color: #0a72c2;
  }
}

.brandzh {
  letter-spacing: 3px;
}
</style>
