<template>
  <div class="box">
    <Header />
    <mainApp />
    <Footer />
  </div>
</template>

<script>
import Header from './header'
import Footer from './footer'
import mainApp from './mainApp'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    mainApp
  },

  methods: {}
}
</script>

<style scoped lang="less">
.head {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  width: 100%;
  height: 80px;
  background: #ffffff;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 72px;
  box-sizing: border-box;
  .ioc-box {
    width: 204px;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 84px;
    }
  }
  /deep/.el-menu-item {
    height: 80px;
    line-height: 80px;
    width: 136px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    border-bottom: 4px solid #ce0000;
  }

  .right-box {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 36px;
      height: 36px;
    }
    span {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      margin-left: 8px;
    }
  }
}

.box {
  box-sizing: border-box;
}

.popover-ul {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .popover-li {
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ffffff;
    font-size: 14px;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    font-weight: 400;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
