<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import devPixelRatio from '@/utils/devicePixelRatio.js'
export default {
  name: 'Home',
  data() {
    return {}
  },
  created() {
    // new devPixelRatio().init() // 初始化页面比例
  },
  mounted() {
    this.isMobile()
  },
  methods: {
    isMobile() {
      var url = location.href
      var newUrl = ''
      // replace www.test.com with your domain
      if (url.indexOf('www.whzyh.com.cn') !== -1 && navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        //当前如果是移动端
        newUrl = url.replace('www.whzyh.com.cn/', 'www.whzyh.com.cn/mobile/')
        location.href = newUrl
      }
    }
  }
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
  // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #0a72c2 !important;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #0a72c2 !important;
}

.el-checkbox_input.is-focus .el-checkbox_inner {
  border-color: #0a72c2 !important;
}

.el-select-dropdown__list {
  padding: 0;
}

.bottom-box {
  width: 100%;
  height: 376px;
  // background: url(./assets/img/Group32.png) center;
  background-size: 100% 376px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 260px;
  box-sizing: border-box;
  .list-box1 {
    flex: none;
    width: 804px;
    height: 256px;
    display: flex;
    justify-content: space-between;
    ul {
      flex: none;
      display: flex;
      flex-direction: column;
      // align-items: center;
      li:nth-child(1) {
        flex: none;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 20px;
      }
      li {
        flex: none;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 12px;
      }
    }
  }
  .list-box2 {
    flex: none;
    width: 404px;
    height: 256px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-left: 36px;
    ul {
      display: flex;
      flex-direction: column;
      li:nth-child(1) {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 16px;
      }
      li {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 12px;
        span {
          font-weight: 600;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  .list-box3 {
    flex: none;
    width: 140px;
    height: 227px;
    margin-left: 41px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li:nth-child(1) {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 16px;
      }
      li {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 12px;
        img {
          width: 129px;
          height: 135.18px;
        }
      }
    }
  }
}
</style>

<style type="text/css">
.BMap_cpyCtrl {
  display: none;
}
</style>
<style type="text/css">
.anchorBL {
  display: none;
}
</style>
<style>
.el-time-panel__btn.confirm {
  color: #0a72c2 !important;
}

.el-select-dropdown__item.selected {
  color: #0a72c2 !important;
  font-weight: 700 !important;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #0a72c2 !important;
  background: #0a72c2 !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #0a72c2 !important;
}
.el-button--primary {
  background-color: #0a72c2 !important;
  border-color: #0a72c2 !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #0a72c2 !important;
}

.el-date-table td.today span {
  color: #0a72c2 !important;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #fcf5f2 !important;
}

.el-date-table td.available:hover {
  color: #0a72c2 !important;
}
.el-radio__inner:hover {
  border-color: #0a72c2 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0a72c2 !important;
  border-color: #0a72c2 !important;
}

.el-checkbox__inner:hover {
  border-color: #0a72c2 !important;
}

.el-button--default:focus,
.el-button--default:hover {
  color: #0a72c2 !important;
  border-color: #fcf5f2 !important;
  background-color: #fcf5f2 !important;
}

.el-dialog__body {
  font-size: 100% !important;
}

.el-date-table td.today.end-date span,
.el-date-table td.today.start-date span {
  color: #fff !important;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fdf3f3 !important;
  color: #333333 !important;
}

.el-date-table td.current:not(.disabled) span {
  color: #ffffff !important;
  background-color: #0a72c2 !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #0a72c2 !important;
}

.el-button--text {
  color: #0a72c2 !important;
}

.el-checkbox .is-focus .el-checkbox__inner {
  border-color: #0a72c2 !important;
}

.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #0a72c2 !important;
}

.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: 400 !important;
  color: rgba(34, 34, 34, 0.4) !important;
}

.el-breadcrumb__inner {
  color: rgba(34, 34, 34, 0.4) !important;
}
</style>
