import Vue from 'vue'
import Vuex from 'vuex'
import Bus from '@/utils/bus'
import createPersistedstate  from 'vuex-persistedstate'
Vue.use(Vuex)
import { queryMenuList } from "@/api/api";
export default new Vuex.Store({
  state: {
    active: '',
    subactive: '',
    lang:  'zh',
    globalI18nKey: null
  },
  getters: {
  },
  mutations: {
    SET_ACTIVE(state, val){
      state.active = val
    },
    SET_SUBACTIVE(state, val){
      state.subactive = val
    },
    SET_LANGUAGE(state, val){
      state.lang = val
    },
    SET_GLOBAL_MENU(state, val) {
      state.globalI18nKey = val
    }
  },
  actions: {
    changeLanguage({commit}, val){
      commit('SET_LANGUAGE', val)
    },
    queryMenuList1( {commit},i18nKey) {
      queryMenuList(i18nKey).then((res) => {
          commit("SET_GLOBAL_MENU", res);
          Bus.$emit('globalMenu', res);
      });
    }
  },
  modules: {
  },
  plugins:[
    createPersistedstate({
      storage: window.localStorage, 
      key:'Language',
      // paths: ['Language'] // 要把那些模块加入缓存
    }) 
  ]

})
