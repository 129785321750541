import Vue from "vue";
import VueRouter from "vue-router";
/* Layout */
import Layout from "@/layout";
import subLayout from "@/layout/subLayout";
import Bus from "@/utils/bus";

Vue.use(VueRouter);
export const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/index",
    children: [
      {
        // 首页index
        path: "",
        name: "homeIndex",
        component: subLayout,
        meta: { title:'homeIndex'},
        component: () => import("../views/homeIndex/index.vue")
      },
      {
        path: "AboutUs",
        name: "AboutUs",
        component: subLayout,
        meta: { title: "AboutUs" },
        redirect: "/AboutUs/index",
        children: [
          {
            path: "index",
            name: "AboutUsIndex",
            meta: { title: "AboutUsIndex" },
            component: () => import("../views/AboutUs/index.vue"),
          },
          {
            path: "history",
            name: "Course",
            meta: { title: "Course" },
            component: () => import("../views/AboutUs/history.vue"),
          },
          {
            path: "idea",
            name: "Idea",
            meta: { title: "Idea" },
            component: () => import("../views/AboutUs/philosophy.vue"),
          },
          {
            path: "target",
            name: "Target",
            meta: { title: "Target" },
            component: () => import("../views/AboutUs/target.vue"),
          },
          {
            path: "mission",
            name: "Mission",
            meta: { title: "Mission" },
            component: () => import("../views/AboutUs/mission.vue"),
          },
        ],
      },
      {
        path: "pipeline",
        name: "Pipeline",
        component: subLayout,
        meta: { title: "Pipeline" },
        // redirect: "/pipeline/index",
        component: () => import("../views/pipeLine/index.vue"),
        // children: [
        //   {
        //     path: "index",
        //     name: "PipelineIndex",
        //     meta: { title: "PipelineIndex" },
        //     bannerUrl:require('../assets/imgs/2.png'),
        //     component: () => import("../views/pipeLine/index.vue"),
        //   },
        // ],
      },
      {
        path: "clinical",
        name: "Clinical",
        component: subLayout,
        bannerUrl:require('../assets/imgs/2.png'),
        meta: { title: "ClinicalIndex" },
        component: () => import("../views/Clinical/index.vue"),
      },
      {
        path: "news",
        name: "News",
        component: subLayout,
        meta: { title: "title" },
        redirect: "/news/index",
        bannerUrl:require('../assets/imgs/1.png'),
        children: [
          {
            path: "index",
            name: "NewsIndex",
            meta: { title: "NewsIndex",keepAlive:true },
            bannerUrl:require('../assets/imgs/1.png'),
            component: () => import("../views/NewsUpdates/index.vue"),
          },
          {
            // 前沿研究  前沿创研
            path: "information",
            name: "frontierResearch",
            meta: { title: "frontierResearch" ,keepAlive:true},
            bannerUrl:require('../assets/imgs/1.png'),
            component: () => import("../views/NewsUpdates/information.vue"),
          },
          {
            // 交易并购  商业案例
            path: "businessDeal",
            name: "businessDeal",
            meta: { title: "businessDeal" ,keepAlive:true},
            component: () => import("../views/NewsUpdates/businessDeal.vue"),
          },
          //国内法规   政策法规
          {
            path: "domesticRegulation",
            name: "domesticRegulation",
            meta: { title: "domesticRegulation",keepAlive:true },
            component: () => import("../views/NewsUpdates/domesticRegulation.vue"),
          },
         
          {
            path: "detial",
            name: "detial",
            meta: { title: "detial",hidden:true ,keepAlive:false},
            bannerUrl:require('../assets/imgs/1.png'),
            component: () => import("../views/NewsUpdates/detial.vue"),
          },
        ],
      },
      {
        path: "talent",
        name: "Talent",
        component: subLayout,
        meta: { title: "Talent" },
        redirect: "/talent/index",
        bannerUrl:require('../assets/imgs/6.png'),
        children: [
          {
            path: "index",
            name: "TalentIndex",
            meta: { title: "TalentIndex" },
            bannerUrl:require('../assets/imgs/6.png'),
            component: () => import("../views/talentDevelop/index.vue"),
          },
          {
            path: "training",
            name: "TalentTraining",
            meta: { title: "TalentTraining" },
            bannerUrl:require('../assets/imgs/7.png'),
            component: () => import("../views/talentDevelop/training.vue"),
          },
          // 查看地图
          {
            path: "mapPage",
            name: "mapPage",
            meta: { title: "mapPage",hidden:true },
            component: () => import("../views/talentDevelop/mapPage.vue"),
          },
        ],
      },
      {
        path: "contact",
        name: "Contact",
        component: subLayout,
        meta: { title: "Contact" },
        redirect: "/contact/us",
        bannerUrl:require('../assets/imgs/6.png'),
        children: [
         
          {
            path: "us",
            name: "concatUs",
            meta: { title: "concatUs" },
            component: () => import("../views/contactUs/index.vue"),
            bannerUrl:require('../assets/imgs/6.png'),
          },
        ],
     
      },
    ],
  },
];

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: process.env.VUE_APP_PATTERN,
  // mode: 'history',
  base: process.env.VUE_APP_PATH == "./" ? "/" : process.env.VUE_APP_PATH,
  routes,
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      y: 0,
    };
  },
});


export default router;
