<template>
  <div class="outerBox">
    <div class="header">
      <div class="header-menu">
        <img class="logo-img" src="../assets/imgs/Slice 26.png" />
        <div class="menu-list">
          <template v-for="(item, index) in routes[0].children">
            <div class="menu-item" :key="index" v-if="item.children && item.children.length > 1" @click="menuClick(item)" style="margin-left: 10px" :class="{ active: isActive(item) }">
              <el-popover placement="bottom" trigger="hover" :close-delay="0.5" popper-class="popoverStyle">
                <ul class="popover-ul">
                  <template v-for="(subItem, index) in item.children">
                    <li :key="index" class="popover-li hand" v-if="!subItem.meta.hidden" :class="{ subactive: isSubActive(subItem) }" @click="skipPage(item, subItem)">{{ globalI18nKey[subItem.meta.title] }}</li>
                  </template>
                </ul>
                <span class="btnLevel" slot="reference">{{ globalI18nKey[item.meta.title] }}</span>
              </el-popover>
            </div>
            <div v-else class="menu-item" :key="index + 100" :index="item.redirect ? item.redirect : '/homepage'" @click="menuClick(item)" style="margin-left: 10px" :class="{ active: isActive(item) }">{{ globalI18nKey[item.meta.title] }}</div>
          </template>
        </div>
        <div style="cursor: pointer; margin-left: 20px; margin-top: 1px; font-size: 15px"><span :class="[lang == 'zh' ? 'language-active' : '']" @click="langChange('zh')">中文</span>/ <span :class="[lang == 'en' ? 'language-active' : '']" @click="langChange('en')">EN</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'
import { queryMenuList } from '@/api/api'
import Bus from '@/utils/bus'
export default {
  components: {},
  data() {
    return {
      bannerUrl: '',
      routes,
      langList: [
        { label: '中文', value: 'zh' },
        { label: 'English', value: 'en' }
      ],
      langLabel: '中文',
      currentMessage: {}
    }
  },

  computed: {
    active: {
      get() {
        return this.$store.state.active
      },
      set(val) {
        this.$store.commit('SET_ACTIVE', val)
      }
    },
    lang() {
      return this.$store.state.lang
    },
    globalI18nKey() {
      return this.$store.state.globalI18nKey
    },

    subactive: {
      get() {
        return this.$store.state.subactive
      },
      set(val) {
        this.$store.commit('SET_SUBACTIVE', val)
      }
    }
  },
  watch: {
    lang: {
      handler(val) {
        if (val) {
          this.$store.dispatch('queryMenuList1', val)
        }
      }
    },
    '$route.path': {
      immediate: true,
      handler(val) {
        const temp = val.split('/')[1]
        console.log('temp==', temp)

        if (temp == 'AboutUs' || temp == 'news' || temp == 'talent') {
          this.$store.commit('SET_ACTIVE', '/' + temp + '/index')
        } else if (val == '/' || temp == '') {
          this.$store.commit('SET_ACTIVE', '/')
        } else if (temp == 'contact') {
          this.$store.commit('SET_ACTIVE', '/' + temp + '/us')
        } else {
          this.$store.commit('SET_ACTIVE', '/' + temp)
        }
      }
    }
  },
  mounted() {
    Bus.$on('onactive', (val) => {
      this.active = val
    })
    console.log('globalI18nKey', this.globalI18nKey)
    this.queryMenuList(this.lang)
  },
  created() {},
  methods: {
    // 只点击二级
    skipPage(item, subItem) {
      const path = '/' + item.path + '/' + subItem.path
      const parentPath = item.redirect ? item.redirect : '/' + item.path
      this.subactive = subItem.name
      this.active = parentPath
      this.$router.push({
        path: path
      })
    },
    // 只点击一级
    menuClick(item) {
      const path = item.redirect ? item.redirect : '/' + item.path
      const subPath = item.children ? item.children[0].name : item.name
      this.active = path
      this.subactive = subPath
      this.$router.push({
        path: path
      })
    },
    isActive(item) {
      const path = item.redirect ? item.redirect : '/' + item.path
      return path == this.active
    },
    isSubActive(subItem) {
      return subItem.name == this.subactive
    },
    isLabelActive(label) {
      this.langLabel = label
    },
    langChange(val) {
      this.queryMenuList(val)
      localStorage.setItem('locale', val)
      this.$store.dispatch('changeLanguage', val)
    },
    queryMenuList(i18nKey) {
      queryMenuList(i18nKey).then((res) => {
        if (res.code == 200) {
          this.$store.commit('SET_GLOBAL_MENU', res?.data)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../assets/styles/variable.less';
.outerBox {
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: #fbfbfb;
  display: flex;
}
.header {
  display: flex;
  height: 80px;
  line-height: 1;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 120px;
  font-family: 'Microsoft YaHei' !important;
  font-weight: 500;
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #000000;
  }

  &-menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .logo-img {
      width: 275px;
      height: 55px;
      display: block;
      object-fit: cover;
    }

    .reference {
      width: 150px;
      height: 30px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 500;
      font-size: 20px;
      color: #0a72c2;
      line-height: 23px;
      text-align: center;
    }

    .menu-item {
      min-width: 110px;
      text-align: center;
      box-sizing: border-box;
      padding: 30px 15px;
      cursor: pointer;
      font-size: 24px;
      margin-right: 30px;

      &:hover {
        color: #fff;
        background: #0a72c2;
      }
    }
    .menu-item:last-child {
      margin-right: 0;
    }
  }
  .btnLevel {
    padding: 20px 0;
  }
  &.fixedToTop {
    position: fixed;

    width: 100%;
  }

  &.main-color {
    background-color: rgba(255, 255, 255, 0.8);

    .navbar-item {
      &:hover {
        color: @main-color;
      }
    }

    .login-text {
      color: @main-color;
    }
  }

  &.is-transparent {
    color: #fff;

    .navbar-item {
      &:hover {
        color: #ccc;
      }
    }

    .active {
      color: #fff;
    }
  }
}
.active {
  color: #fff;
  background: #0a72c2;
}

.language-active {
  width: 80px;
  width: 150px;
  height: 30px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 500;
  color: #0a72c2;
  line-height: 23px;
  text-align: center;
  position: relative;
}

.subactive {
  width: 81px;
  height: 25px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
  background: #0a72c2;
  border-radius: 6px;
}

.popover-ul {
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  .lang-img {
    display: block;
    width: 44px;
    height: 28px;
    border-radius: 50%;
  }

  .popover-li {
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #ffffff;
    font-size: 20px;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popover-li:hover {
    background: #0a72c2;
    color: #fff;
    border-radius: 6px;
  }
}
.lang-img {
  cursor: pointer;
}
::v-deep .popoverStyle {
  min-width: 180px;
}
</style>
