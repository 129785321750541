<template>
  <div>
    <div class="brief-introduction-box">
      <div class="brief-introduction">
        <ul class="tab-box">
          <li class="hand" :class="atpresent.id == item.id ? 'pitch-on-li' : 'unselected'" v-for="(item, index) in tabdatalist" :key="index" @click="transferclick(item)">{{ item.title }}</li>
        </ul>
        <div class="current-position">
          <span>当前位置：</span>
          <el-breadcrumb separator=" - ">
            <el-breadcrumb-item :to="{ path: '/' }" replace>首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: navigation.to }" replace>{{ navigation.name }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ atpresent.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabdatalist', 'navigation', 'atpresent'],
  data() {
    return {}
  },
  methods: {
    transferclick(item) {
      this.$emit('change', item)
    }
  }
}
</script>

<style scoped lang="less">
.brief-introduction-box {
  width: 100%;
  padding: 100px 260px 0;
  box-sizing: border-box;
  background-color: #f3f3f3;

  .brief-introduction {
    width: 100%;
    flex: none;
    height: 136px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab-box {
      flex: none;
      height: 40px;
      display: flex;
      align-items: center;

      li {
        margin-right: 16px;
        padding: 8px 24px;
        box-sizing: border-box;
      }

      li:last-child {
        margin-right: 0;
      }
      .pitch-on-li {
        background: #ce0000;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .unselected {
        background: rgba(34, 34, 34, 0.3);
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-out 0s;
      }

      .unselected:hover {
        background: #ce0000 !important;
      }
    }
    .current-position {
      flex: none;
      height: 72px;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(34, 34, 34, 0.4) !important;
      }
    }
  }
}
</style>
