import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // 引入我们前面导出的store对象
// 全局样式重置
import "./assets/css/common.less";
import "@/assets/styles/reset.css";
import "@/utils/quillEditor.css";
import "@/assets/styles/global.css";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ElementUI from "element-ui";

ElementUI.Dialog.props.closeOnClickModal.default = false;
ElementUI.Dialog.props.lockScroll.default = false;
import "element-ui/lib/theme-chalk/index.css";
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts
// import xlsx from './utils/excel.js'
// Vue.prototype.$xlsx = xlsx
// import VueClipboard from 'vue-clipboard2'
// Vue.use(VueClipboard)
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper, {});

import tabHeadNavigation from "@/components/tabHeadNavigation.vue";
Vue.component("tab-head-navigation", tabHeadNavigation);

import baiduMap from "vue-baidu-map";
Vue.use(baiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "v9KIPGzD5w4fNLhqqj9Oy3p38GnTws79",
});

import "fullpage.js/vendors/scrolloverflow";
// import 'vue-fullpage.js/dist/style.css'
import VueFullPage from "vue-fullpage.js";
Vue.use(VueFullPage);

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  store.dispatch('queryMenuList1', store.state.lang)
  next();
});

// import Fragment from 'vue-fragment'
// Vue.use(Fragment.Plugin)
Vue.prototype.$axiosUrl =
  process.env.VUE_APP_BASE_URL + "jeecg-boot/sys/common/static/";

Vue.config.productionTip = false;

Vue.use(ElementUI);

var vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
